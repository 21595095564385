/* You can add global styles to this file, and also import other style files */
@use './font/font.css';
// @import '~swiper/swiper-bundle';

*{
    box-sizing: border-box;
  }
  body, html {
    height: 100%;
    width: 100%;
    margin: 0;
    scroll-behavior: smooth;
  }