@font-face {
    font-family: 'baloo_2medium';
    src: url('baloo2-medium-webfont.woff2') format('woff2'),
         url('baloo2-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'baloo_2bold';
    src: url('baloo2-bold-webfont.woff2') format('woff2'),
         url('baloo2-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'baloo_2extrabold';
    src: url('baloo2-extrabold-webfont.woff2') format('woff2'),
         url('baloo2-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'baloo_2regular';
    src: url('baloo2-regular-webfont.woff2') format('woff2'),
         url('baloo2-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'baloo_2semibold';
    src: url('baloo2-semibold-webfont.woff2') format('woff2'),
         url('baloo2-semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'quicksandlight';
    src: url('quicksand-variablefont_wght-webfont.woff2') format('woff2'),
         url('quicksand-variablefont_wght-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}